.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #202c33;
    border-bottom: 1px solid #2a3942;
  }
  
  .header-info {
    flex-grow: 1;
    margin-left: 15px;
  }
  
  .header-info h4 {
    margin: 0;
    color: #d1d1d1;
  }
  
  .header-info small {
    color: #8696a0;
  }
  
  .header-actions svg {
    margin-left: 15px;
    cursor: pointer;
    color: #8696a0;
  }
  