.sidebar {
    width: 100%;
    background-color: #1f2c34;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #2a3942;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    border-bottom: 1px solid #2a3942;
  }
  
  .sidebar-header .sidebar-actions svg {
    margin-left: 15px;
    cursor: pointer;
    color: #8696a0;
  }
  
  .sidebar-chats {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .chat {
    padding: 15px 20px;
    border-bottom: 1px solid #2a3942;
    cursor: pointer;
  }
  
  .chat:hover {
    background-color: #2a3942;
  }
  
  .chat p {
    margin: 0;
    font-size: 1em;
  }
  
  .chat small {
    display: block;
    margin-top: 5px;
    color: #8696a0;
    font-size: 0.9em;
  }
  