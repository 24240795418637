/* Style for the container */
.whatsapp-login {
    display: flex;
    height: 100vh;
  }
  
  /* Sidebar styles */
  .sidebar {
    width: 80px;
    background-color: #3d4241;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: white;
  }

  .sidebar2 {
    width: 3%;
    background-color: #3d4241;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: white;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-icon {
    font-size: 36px;
    cursor: pointer;
  }
  
  /* Login area styles */
  .login-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  
  /* Title and instructions */
  .title {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* QR Code container */
  .qr-code-container {
    margin-bottom: 20px;
  }
  
  /* Footer */
  .footer {
    font-size: 14px;
  }
  
  /* Links */
  .footer a {
    color: #25d366;  /* WhatsApp green color */
    text-decoration: none;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px;
    }
  
    .title {
      font-size: 28px;
    }
  
    .instructions {
      font-size: 16px;
    }
  
    .qr-code-container {
      margin-bottom: 15px;
    }
  }
  