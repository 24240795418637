/* sideMenu.css */

.menu {
  background-color: #2a3942;
  color: #d1d1d1;
  height: 100vh;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.menu.expanded {
  width: 15%;
}

.menu-toggle {
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  color: #d1d1d1;
}

.menu-list,
.bottom-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: flex;
  align-items: left;
  cursor: pointer;
  padding: 10px;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #3c4852;
}

.menu .icon {
  font-size: 20px;
  padding-left: 10px;
}

.menu .label {
  flex-grow: 1;
  font-size: 16px;
}

/* Bottom menu styling */
.bottom-menu {
  margin-bottom: 20px; /* Space from the bottom of the menu */
}

.bottom-menu .menu-item {
  justify-content: flex-start;
}
