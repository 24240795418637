.chat-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .messages {
    flex-grow: 1;
    padding: 10px 20px;
    overflow-y: auto;
    background-color: #121b22;
  }
  